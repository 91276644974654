import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  Typography,
  Box,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import guitarBackground from "../assets/images/backgroundGuitar.png";
import popIcon from "../assets/images/categories/pop.png";
import rockIcon from "../assets/images/categories/rock.png";
import reggaetonIcon from "../assets/images/categories/reggaeton.png";
import acousticIcon from "../assets/images/categories/acoustic.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar/SearchBar";
import { supabase } from "../lib/helper/supabaseClient";
import { getUserRoleByID } from "../functions/general";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useIntl } from "react-intl";

import imagenBaladas from "../assets/images/GENEROS/baladas.jpg";
import imagenPop from "../assets/images/GENEROS/pop.jpg";
import imagenAcustico from "../assets/images/GENEROS/acústico.jpg";
import imagenReggaeton from "../assets/images/GENEROS/reggaeton.jpg";
import imagenRock from "../assets/images/GENEROS/rock.jpg";
import imagenSalsa from "../assets/images/GENEROS/salsa.jpg";
import imagenBolero from "../assets/images/GENEROS/bolero.jpg";
import imagenElectronica from "../assets/images/GENEROS/electronica.jpg";
import imagenFlamenco from "../assets/images/GENEROS/flamenco.jpg";
import imagenFunk from "../assets/images/GENEROS/funk.jpg";
import imagenIndie from "../assets/images/GENEROS/indie.jpg";
import imagenJazz from "../assets/images/GENEROS/jazz.jpg";
import imagenRanchera from "../assets/images/GENEROS/ranchera.jpg";
import imagenRnb from "../assets/images/GENEROS/rnb.jpg";



const Root = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#0e0e0e",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${guitarBackground})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down('lg')]: {
    backgroundImage: "none",
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: '100%',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  textAlign: "left",
  padding: theme.spacing(0, 2, 8, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 4, 8, 4),
    maxWidth: '100%',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 8, 16, 4),
    maxWidth: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 8, 16, 20),
    maxWidth: '60%',
  },
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(0, 8, 16, 20),
    maxWidth: '50%',
  },
}));



const ServicesSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: "#fff",
  color: "#000",
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 0),
    maxWidth: '100%',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 8),
    maxWidth: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(8, 20),
    maxWidth: '90%',
  },
}));


const ServiceCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  textAlign: "center",
  "& img": {
    width: "60%",
    height: "auto",
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
  },
}));




const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};


const HomepageScreen = () => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const intl = useIntl();
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const checkUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);

      if (user) {
        const userID = user.id;
        console.log("user id: ", userID)
        const { role } = await getUserRoleByID(userID);
        setUserRole(role);
        console.log(`User role is: ${role}`);
        localStorage.setItem("userRole", role);
      } else {
        console.log("No user logged in.");
      }
    };

    checkUser();
  }, []);


  return (
    <div>
      <Root>
        <HeroContent>
          <Typography variant="h2" component="h1" gutterBottom>
            <span style={{ fontWeight: 100 }}>
              {intl.formatMessage({ id: "homepage.heroTitlePart1" })}
            </span>{" "}
            <span style={{ color: "#af8e56", fontWeight: 700 }}>
              {intl.formatMessage({ id: "homepage.heroTitlePart2" })}
            </span>
          </Typography>

          <Typography variant="h6" component="p" gutterBottom>
            {intl.formatMessage({ id: "homepage.heroDescription" })}
          </Typography>
          <Box sx={{ display: "flex", width: "100%", mt: 2 }}>
            <SearchBar isHomePage={true} />
          </Box>
        </HeroContent>
      </Root>

      <ServicesSection>
        <Typography variant="h2" component="h2" gutterBottom align="left">
          <span style={{ fontWeight: 100, paddingLeft: "10%" }}>{intl.formatMessage({ id: "homepage.servicesTitle" }).split(" ")[0]}</span>{" "}
          <span style={{
            color: "black", fontWeight: 700, paddingLeft: isSmUp ? "0%" : "10%",
          }}>
            {intl.formatMessage({ id: "homepage.servicesTitle" }).split(" ")[1]}
          </span>
        </Typography>
        <Typography variant="body1" gutterBottom align="left" style={{ paddingBottom: "5%" }}>
          <span style={{ color: "#626262", fontWeight: 100, paddingLeft: "10%", display: "block" }}>
            {intl.formatMessage({ id: "homepage.servicesDescription" })}
          </span>
        </Typography>
        <Box sx={{ paddingLeft: "10%" }}>
          <Slider {...sliderSettings}>
            {Object.entries({
              baladas: imagenBaladas,
              pop: imagenPop,
              acustico: imagenAcustico,
              reggaeton: imagenReggaeton,
              rock: imagenRock,
              salsa: imagenSalsa,
              bolero: imagenBolero,
              electronica: imagenElectronica,
              flamenco: imagenFlamenco,
              funk: imagenFunk
            }).map(([genre, image]) => (
              <ServiceCard key={genre}>
                <img src={image} alt={intl.formatMessage({ id: `homepage.genres.${genre}` })} />
              </ServiceCard>
            ))}
          </Slider>
        </Box>
      </ServicesSection>
    </div>
  );
};

export default HomepageScreen;
