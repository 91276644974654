import { supabase } from "../lib/helper/supabaseClient";
import { registerAuthUser } from "./general";
import { fetchAllGenres, registerGenres } from "./genre";

export const registerArtist = async (artistData) => {
  console.log("artistData:", artistData);
  const { email, password, genres, ...artistDetails } = artistData;

  try {
    // Register the user in Supabase Auth
    const { success, user, authError } = await registerAuthUser(email, password);

    if (!success) {
      console.error("Error al registrar en Auth:", authError || "Error desconocido.");
      return { success: false, message: authError || "Hubo un problema al registrar al usuario. Inténtalo de nuevo." };
    }

    // If user requires email verification
    if (!user) {
      return { success: true, message: "Revisa tu correo para confirmar tu cuenta." };
    }

    const userId = user.id;

    // Fetch all existing genres from the database
    const existingGenreNames = await fetchAllGenres();
    if (!existingGenreNames || !Array.isArray(existingGenreNames)) {
      console.error("Error: No se pudieron obtener los géneros existentes.");
      return { success: false, message: "Error al obtener géneros. Inténtalo más tarde." };
    }

    console.log("existing genres: ", existingGenreNames);

    // Separate genres into existing and new
    const newGenres = genres.filter((genre) => !existingGenreNames.includes(genre));

    // Register new genres
    const genreRegistrationResult = await registerGenres(newGenres);
    if (!genreRegistrationResult.success) {
      return { success: false, message: genreRegistrationResult.message };
    }

    // Insert artist into the artist table with enEspera set to true
    const { data: artist, error: artistError } = await supabase
      .from("artist")
      .insert({ id: userId, email, enEspera: true, ...artistDetails })
      .select()
      .single();

    if (artistError) {
      console.error("Error al registrar al artista:", artistError);
      return { success: false, message: "Error al registrar al artista. Inténtalo más tarde." };
    }

    // Associate the artist with genres in the artist_genres table
    for (const genre of genres) {
      const { error: associationError } = await supabase
        .from("artist_genres")
        .insert({ artist_id: userId, genre_name: genre });
      if (associationError) {
        console.error("Error al asociar artista con género:", genre, associationError);
        return { success: false, message: "Error al asociar artista con géneros. Inténtalo más tarde." };
      }
    }
    console.log("Artista registrado con éxito:", artist);
    return { success: true, message: "Artista registrado con éxito.", artist };
  } catch (error) {
    console.error("Error inesperado durante el registro:", error.message || error);
    return { success: false, message: "Ocurrió un error inesperado. Por favor, inténtalo más tarde." };
  }
};





export const getArtistNameById = async (artistId) => {
  const { data, error } = await supabase
    .from("artist")
    .select("stageName")
    .eq("id", artistId)
    .single();

  if (error) {
    console.error("Error fetching artist:", error);
    throw error;
  }

  return data;
};



export const getArtistByID = async (id) => {
  const { data, error } = await supabase
    .from("artist")
    .select("*")
    .eq("id", id)
    .single();

  if (!data) {
    console.log("No matching artist found.");
    return; // You might want to return or handle this case in the UI
  }

  if (error) {
    console.error("Error fetching artist:", error);
    throw error;
  }

  return data;
};

export const getArtistsByUsername = async (username) => {
  const { data, error } = await supabase
    .from("artist")
    .select("*")
    .ilike("username", `%${username}%`);

  if (error) {
    console.error("Error fetching data:", error);
  }

  return data; // This will return the filtered rows
};
export const getArtistsStageName = async (stageName) => {
  const { data, error } = await supabase.from("artist").select("*");

  if (error) {
    console.error("Error fetching data:", error);
    return [];
  }

  // Normalize search input and results for case-insensitive matching
  return data.filter(
    (artist) =>
      artist.stageName &&
      artist.stageName.toLowerCase() === stageName.toLowerCase()
  );
};

export const getArtistsStageName2 = async (stageName) => {
  const trimmedStageName = stageName.trim();
  console.log("stageName:", JSON.stringify(trimmedStageName));
  const pattern = `%${trimmedStageName}%`;
  const { data, error } = await supabase
    .from("artist")
    .select("*")
    .ilike("stageName", pattern);

  if (error) {
    console.error("Error fetching data:", error);
    return null;
  }
  console.log("data:", data);

  return data.length > 0 ? data[0] : null;
};





export const getArtistsByGenre = async (genre) => {
  const { data, error } = await supabase.from("artist").select("*");

  if (error) {
    console.error("Error fetching artists:", error);
    throw error;
  }

  const normalizeText = (text) =>
    text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/s+$/, ""); // Elimina 's' finales si existen

  const normalizedGenre = normalizeText(genre);

  return data.filter(
    (artist) =>
      artist.genres &&
      artist.genres.some((g) => normalizeText(g) === normalizedGenre)
  );
};


export const getArtistsByGenres = async (genres) => {
  if (!Array.isArray(genres) || genres.length === 0) {
    throw new Error("genres must be a non-empty array");
  }

  // Remove any spaces from genres and convert to lowercase
  const cleanedGenres = genres.map((genre) => genre.toLowerCase().trim());

  const { data, error } = await supabase.from("artist").select("*");

  if (error) {
    console.error("Error fetching artists:", error);
    throw error;
  }

  // Normalize search input and results for case-insensitive matching
  return data.filter(
    (artist) =>
      artist.genres &&
      artist.genres.some(
        (g) => cleanedGenres.includes(g.toLowerCase().trim()) // Ensure both sides are cleaned
      )
  );
};

